.section-custom-hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: white;
}

.section-custom-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.section-custom-hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.darkOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: none;
}

.overlay-content {
    max-width: 375px;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 60px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
}

.overlay-title {
    color: black;
    width: 400px;
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: -5px;
    text-transform: uppercase;
}

.overlay-text {
    font-size: 16px;
    color: black;
    margin-top: 15px;
    margin-bottom: 30px;
}

.buttons-row {
    display: flex;
    gap: 20px;
}

.overlay-button {
    padding: 8px 12px;
    cursor: pointer;
    border: none;
    background-color: var(--marketplaceColor);
    width: 140px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    border-radius: 16px;
}

@media (max-width: 1200px) {
    .section-custom-hero {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        height: 100vh;
        max-height: 600px;
    }

    .darkOverlay {
        display: block;
    }

    .section-custom-hero-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .overlay-content {
        position: relative;
        z-index: 2;
        max-width: 100%;
        padding: 20px;
        margin: 0;
        height: 100%;
        padding-top: 40px;
        justify-content: flex-start;
        align-items: center;
    }

    .overlay-title {
        font-size: 30px;
        color: var(--colorWhite);
        margin-bottom: 0px;
        text-align: center;
        font-weight: 400;
        width: 100%;
    }

    .overlay-text {
        font-size: 16px;
        color: var(--colorWhite);
        margin-bottom: 20px;
        text-align: center;
        padding-left: 25px;
        padding-right: 25px;
    }

    .overlay-button {
        padding: 12px 20px;
        font-size: 14px;
        border: 0.5px solid;
        border-color: white;
        align-self: center;
    }
}

@media (max-width: 768px) {
    .overlay-title {
        font-size: 24px;
        color: var(--colorWhite);
        margin-bottom: 0px;
        text-align: center;
        font-weight: 400;
        max-width: 300px;
    }

    .overlay-text {
        font-size: 14px;
        color: var(--colorWhite);
        text-align: center;
        margin-bottom: 20px;
        padding-left: 25px;
        padding-right: 25px;
        max-width: 300px;
    }

    .overlay-button {
        padding: 12px 20px;
        font-size: 12px;
        border: 0.5px solid;
        border-color: white;
    }
}